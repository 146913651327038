/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export type FlatDataType = Record<
  string,
  number | string | null | Array<number>
>;
export interface TableDataType {
  data: Array<FlatDataType>;
  labels: Array<string>;
  keys: Array<string>;
}

// 改行コード何がいいのかは要相談
const LF = '\r\n';
const HEADER_BODY_SEP_NUM = 5;

const CHUNK_SIZE = 10000;

// headerLabels もここに含めた方が見通しはいいかもしれない。。
type HeaderType = Record<string, string | Array<string>> | undefined;
type AcceptableFileType = 'csv';

const _round = (val: number, decimalPlace: number): number => {
  if (decimalPlace > 0) {
    const d = 10 ** (decimalPlace + 1);
    return Math.round(val * d) / d;
  }
  return val;
};

const download = <T extends Array<Record<string, unknown>> | TableDataType>(
  _data: T,
  _fileName: string,
  _header: HeaderType = undefined,
  _headerLabels: Record<string, string> | undefined = undefined,
  _title?: string,
  _decimalPlace = 0,
  _fileType: AcceptableFileType = 'csv',
  _with_bom = true
): void => {
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  const fileName = `${_fileName}.${_fileType}`;
  // fileType が増えるようなら lambda 関数でも作って対応
  const strHeader = map2Csv(_header, _headerLabels, _title);
  const strData = Array.isArray(_data)
    ? object2Csv(_data, _decimalPlace)
    : table2Csv(_data, _decimalPlace);
  const data = _with_bom ? [bom, strHeader, strData] : [strHeader, strData];
  const blobData = new Blob(data, {
    type: `text/${_fileType}`
  });
  downloadFile(blobData, fileName);
};

const chunk = <T>(data: Array<T>): Array<Array<T>> => {
  const size = data.length;
  const result = Array<Array<T>>();
  for (let i = 0; i < size; i += CHUNK_SIZE) {
    const end = i + CHUNK_SIZE;
    if (end < size) {
      result.push(data.slice(i, end));
    } else {
      result.push(data.slice(i));
    }
  }
  return result;
};

export const downloadFile = (data: Blob, fileName: string) => {
  const aTag = document.createElement('a');
  aTag.download = fileName;
  aTag.href = URL.createObjectURL(data);
  aTag.target = '_blank';
  aTag.click();
};

// 本当なら同一 csv の中にデータ構造が違うもの入れるの気持ち悪いから辞めたいが仕様なので入れる。。
const map2Csv = (
  _data: HeaderType,
  _labels?: Record<string, string>,
  _title?: string
): string => {
  const data: Array<string> = [];
  if (_title) {
    data.push(_title);
  }
  if (_data) {
    Object.entries(_data).forEach(value => {
      const label = _labels && _labels[value[0]] ? _labels[value[0]] : value[0];
      if (Array.isArray(value[1])) {
        const val = value[1].map(val => `"${val}"`).join(',');
        data.push(`${label}:,${val}`);
      } else {
        data.push(`${label}:,"${value[1]}"`);
      }
    });
    // header と body 部分の空白用
    const sep = new Array(HEADER_BODY_SEP_NUM);
    data.push(...sep);
  }
  return data.join(LF);
};

// 処理の重さによっては非同期にすることも考慮
// ポイント
// - 他の操作を阻害するような動きになるかどうか
// - ボタンにローディングを入れたいかどうか
const object2Csv = <T extends Record<string, unknown>>(
  _data: Array<T>,
  _decimalPlace: number
): string => {
  if (_data.length === 0) {
    throw new Error('block downloading empty data from button');
  }
  const data: Array<string> = [];
  const keys = Object.keys(_data[0]);
  data.push(keys.join(','));
  const chunkedData = chunk(_data);
  chunkedData.forEach(chunk => {
    const _body = chunk.map(d => dataToCsv(d, keys, _decimalPlace));
    data.push(..._body);
  });
  return data.join(LF);
};

const table2Csv = (_data: TableDataType, _decimalPlace: number) => {
  if (
    _data.data.length === 0 ||
    _data.keys.length === 0 ||
    _data.labels.length === 0
  ) {
    throw new Error('block downloading empty data from button');
  }
  const data: Array<string> = [];
  const labels = _data.labels;
  const keys = _data.keys;
  data.push(labels.join(','));
  const chunkedData = chunk(_data.data);
  chunkedData.forEach(chunk => {
    const _body = chunk.map(d => dataToCsv(d, keys, _decimalPlace));
    data.push(..._body);
  });
  return data.join(LF);
};

const dataToCsv = (
  data: FlatDataType | Record<string, unknown>,
  keys: Array<string>,
  decimalPlace: number
) => {
  const result = keys.map(key => {
    if (typeof data[key] === 'number') {
      return _round(Number(data[key]), decimalPlace);
    } else if (typeof data[key] === 'string') {
      return `"${data[key]}"`;
    } else if (data[key] === undefined) {
      return '';
    } else {
      return `"${JSON.stringify(data[key]).replace(/"/g, '""')}"`;
    }
  });
  return result.join(',');
};

export default download;

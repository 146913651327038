/* eslint-disable @typescript-eslint/no-explicit-any */
import { CustomTargetApi, TvDataSearchApi } from '@/api';
import {
  AreaInfoIdEnum,
  CmSponsor,
  CustomTarget,
  KeyStation,
  SelectableAreasList,
  TvDataSearchCmAggregateHistory,
  TvDataSearchCmCreative,
  TvDataSearchProduct,
  TvDataSearchCmAggregateHistoryViewingTypeEnum,
  WorkspaceFeatureOptionTypeEnum,
  CompanySettings
} from '@/api/openapi';
import { TARGET_NAME, httpCode } from '@/common/constant';
import {
  DATE_FORMAT,
  DATE_FORMAT_SLASH,
  DATE_FORMAT_SLASH_AND_TIME
} from '@/common/format';
import { DateRange } from '@/components/ui/DatePicker.vue';
import { toast } from '@/components/ui/Toast';
import {
  DataTabelType,
  useHistoryModal
} from '@/composables/datasearch/historymodal/historyModal';
import useLoading from '@/composables/loading';
import { useAreaOptions } from '@/store/areaOptions';
import { format } from 'date-fns';
import { storeToRefs } from 'pinia';
import { ComputedRef, Ref, computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useUserInfoStore } from '@/store/userInfo';

interface CheckBoxGroup {
  label: string;
  id: string;
}

interface cmCreativeDataListType extends TvDataSearchCmCreative {
  select: number;
}

// init
export const created = async (
  // maxDate: Ref<Date>,
  areaValue: Ref<AreaInfoIdEnum | undefined>,
  areaOptions: ReturnType<typeof useAreaOptions>,
  areaSelectOptions: Ref<SelectableAreasList[]>,
  route: ReturnType<typeof useRoute>,
  onChangeArea: () => Promise<void>,
  companyId: Ref<number>,
  customTargets: Ref<CustomTarget[]>,
  viewingTypeValue: Ref<TvDataSearchCmAggregateHistoryViewingTypeEnum>,
  isConversion15secValue: Ref<boolean>,
  isTotalOptions: Ref<boolean>,
  companySettings: CompanySettings,
  initDuration: () => void
): Promise<void> => {
  try {
    // エリア情報取得
    (async () => {
      const data = await areaOptions.fetch(route);
      if (data) {
        areaValue.value = data;
        await onChangeArea();
      }
      // 権限: トータルオプション
      const userInfoStore = useUserInfoStore();
      const currentWorkspace = userInfoStore.currentWorkspaceFromRoute(route);
      const featureOptionViewingTypeTotal = currentWorkspace?.featureOptions.find(
        v => v.type === WorkspaceFeatureOptionTypeEnum.ViewingTypeTotal
      );
      // FeatureOptionが存在しないか、statusがENABLEDの場合はtrueそれ以外はfalse
      isTotalOptions.value =
        featureOptionViewingTypeTotal?.status === 'ENABLED';
      viewingTypeValue.value = isTotalOptions.value
        ? ((companySettings.viewingType as unknown) as TvDataSearchCmAggregateHistoryViewingTypeEnum)
        : TvDataSearchCmAggregateHistoryViewingTypeEnum.Realtime;
      // areaValue, viewingTypeValueを取得してから期間初期化
      initDuration();
    })();

    // CM15秒換算
    isConversion15secValue.value = companySettings.isConversion15sec;
    // 視聴種別:権限が有効の場合はCompany設定、無効の場合はリアルタイムに設定
    watch(isTotalOptions, () => {
      // 権限が有効の場合はCompany設定、無効の場合はリアルタイムに設定
      viewingTypeValue.value = isTotalOptions.value
        ? ((companySettings.viewingType as unknown) as TvDataSearchCmAggregateHistoryViewingTypeEnum)
        : TvDataSearchCmAggregateHistoryViewingTypeEnum.Realtime;
    });
    // カスタムターゲット
    const getCustomTargets = await CustomTargetApi.getCompaniesCompanyIdCustomTargets(
      companyId.value
    );
    customTargets.value = getCustomTargets.data.list;
  } catch (e) {
    if (e.state !== httpCode.forbidden) {
      toast({ title: '失敗', message: e.message, variant: 'error' });
    }
  }
};

// 時間帯
export const useTime = (): any => {
  const selectHour = [
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28
  ];
  const startTimeValue = ref({
    HH: '05',
    mm: '00'
  }); //model
  const endTimeValue = ref({
    HH: '28',
    mm: '59'
  }); //model
  const convertStartTimeValue = computed(() => {
    return Object.values(startTimeValue.value).join(':');
  });
  const convertEndTimeValue = computed(() => {
    return Object.values(endTimeValue.value).join(':');
  });
  const convertTimeValid = computed(() => {
    if (
      startTimeValue.value.HH &&
      startTimeValue.value.mm &&
      endTimeValue.value.HH &&
      endTimeValue.value.mm
    ) {
      if (convertStartTimeValue.value >= convertEndTimeValue.value) {
        return '終了時間は開始時間より後にしてください。';
      } else {
        return;
      }
    } else {
      return '時刻を入力してください。';
    }
  });

  return {
    selectHour,
    startTimeValue,
    endTimeValue,
    convertStartTimeValue,
    convertEndTimeValue,
    convertTimeValid
  };
};

// エリア
export const useArea = (
  areaOptionStore: ReturnType<typeof useAreaOptions>,
  areaValue: Ref<AreaInfoIdEnum>,
  companyId: Ref<number>,
  tvStations: Ref<CheckBoxGroup[]>,
  stationsValue: Ref<(string | undefined)[]>,
  tvStationsRawData = ref<Array<KeyStation>>([])
): any => {
  const { selectOptions: areaSelectOptions } = storeToRefs(areaOptionStore);
  const _onChangeArea = async () => {
    const stations = await TvDataSearchApi.getTvdataSearchStations(
      areaValue.value,
      companyId.value,
      true
    );
    tvStationsRawData.value = stations.data;
    tvStations.value = [...stations.data].map(v => ({
      id: v.stationCodes[0],
      label: v.displayStationName
    }));
    stationsValue.value = tvStations.value
      .map(v => v.id)
      .filter(e => typeof e !== 'undefined');
  };
  const [isLoadingOnChangeArea, onChangeArea] = useLoading(_onChangeArea);
  const areaErrorMessage = computed(() => {
    if (!areaValue.value) {
      return '選択してください。';
    } else {
      return undefined;
    }
  });
  return {
    areaSelectOptions,
    onChangeArea,
    isLoadingOnChangeArea,
    areaErrorMessage
  };
};

// 企業、商品／ブランド、CM素材
export const useSponsorsAndProducts = (
  selectStationsCodes: Ref<string[]>,
  duration: Ref<DateRange | undefined>,
  startTimeValue: Ref<{ HH: string; mm: string }>,
  endTimeValue: Ref<{ HH: string; mm: string }>,
  companyId: Ref<number>,
  convertStartTimeValue: ComputedRef<string>,
  convertEndTimeValue: ComputedRef<string>,
  cmTimeValue: Ref<number | undefined>,
  cmTypeValue: Ref<'TIME' | 'SPOT' | undefined>,
  programsIds: ComputedRef<number[] | undefined>
): any => {
  // 企業
  const cmSponsorsValue = ref<Array<CmSponsor>>([]);
  const sponsorsIds = computed(() => {
    if (cmSponsorsValue.value) {
      return cmSponsorsValue.value.map(val => val.id);
    } else {
      return undefined;
    }
  });

  // 商品／ブランド
  const cmProductsValue = ref<Array<TvDataSearchProduct>>();
  const productsIds = computed(() => {
    if (cmProductsValue.value) {
      return cmProductsValue.value.map(val => val.id);
    } else {
      return undefined;
    }
  });

  // CM素材
  const cmCreativeDataListItems: Ref<cmCreativeDataListType[]> = ref([]);
  const selectedCmCreativeIds = ref<number[]>([]);
  const selectedCmCreativeDataListItems: Ref<cmCreativeDataListType[]> = ref(
    []
  );
  const notIncludedListIds = ref<number[]>([]);

  // 企業名取得
  const fetchCmSponsors = async (search: string) => {
    if (
      selectStationsCodes.value.length &&
      duration.value &&
      startTimeValue.value &&
      endTimeValue.value &&
      companyId.value
    ) {
      const getCmSponsors = await TvDataSearchApi.getTvdataSearchCmSponsorsSearch(
        selectStationsCodes.value,
        format(duration?.value?.start ?? new Date(), DATE_FORMAT),
        format(duration?.value?.end ?? new Date(), DATE_FORMAT),
        convertStartTimeValue.value,
        convertEndTimeValue.value,
        companyId.value,
        cmTimeValue.value,
        cmTypeValue.value,
        sponsorsIds.value,
        productsIds.value,
        programsIds.value,
        search
      );
      return getCmSponsors.data;
    }
  };

  // 商品／ブランド取得
  const fetchProducts = async (search: string) => {
    if (
      selectStationsCodes.value.length &&
      duration.value &&
      startTimeValue.value &&
      endTimeValue.value &&
      companyId.value
    ) {
      const getCmProducts = await TvDataSearchApi.getTvdataSearchProductsSearch(
        selectStationsCodes.value,
        format(duration?.value?.start ?? new Date(), DATE_FORMAT),
        format(duration?.value?.end ?? new Date(), DATE_FORMAT),
        convertStartTimeValue.value,
        convertEndTimeValue.value,
        companyId.value,
        cmTimeValue.value,
        cmTypeValue.value,
        sponsorsIds.value,
        productsIds.value,
        programsIds.value,
        search
      );
      return getCmProducts.data;
    }
  };

  // CM素材取得
  const _getCmCreatives = async () => {
    if (!productsIds.value) return;

    if (productsIds.value.length === 0) {
      cmCreativeDataListItems.value = [];
      selectedCmCreativeIds.value = [];
      selectedCmCreativeDataListItems.value = [];
      return;
    }

    if (selectedCmCreativeIds.value.length > 0) {
      // 選択状態のリスト
      selectedCmCreativeDataListItems.value = cmCreativeDataListItems.value.filter(
        v => selectedCmCreativeIds.value.includes(v.id)
      );
    }

    const startDate = format(duration?.value?.start ?? new Date(), DATE_FORMAT);
    const endDate = format(duration?.value?.end ?? new Date(), DATE_FORMAT);
    // CM素材取得
    const result = await TvDataSearchApi.getTvdataSearchCmCreativesSearch(
      startDate,
      endDate,
      productsIds.value,
      companyId.value
    );

    cmCreativeDataListItems.value = result.data.map(v => {
      return {
        ...v,
        select: v.id
      };
    });

    // 前のCM素材取得処理後に選択状態であったが最新のCM素材取得処理レスポンスの一覧には含まれてないリスト
    // (画面で赤字表示するリスト)
    const notIncludedList = selectedCmCreativeDataListItems.value.filter(
      item => !result.data.map(data => data.id).includes(item.id)
    );
    notIncludedListIds.value = notIncludedList.map(v => v.id);

    cmCreativeDataListItems.value = cmCreativeDataListItems.value
      .concat(notIncludedList)
      .sort((a, b) => a.id - b.id);
  };

  const [isLoadingGetCmCreatives, getCmCreatives] = useLoading(_getCmCreatives);

  return {
    cmSponsorsValue,
    sponsorsIds,
    cmProductsValue,
    productsIds,
    fetchCmSponsors,
    fetchProducts,
    getCmCreatives,
    selectedCmCreativeIds,
    cmCreativeDataListItems,
    isLoadingGetCmCreatives,
    notIncludedListIds
  };
};

// 番組名
export const useTvPrograms = (
  selectStationsCodes: Ref<string[]>,
  duration: Ref<DateRange | undefined>,
  startTimeValue: Ref<{ HH: string; mm: string }>,
  endTimeValue: Ref<{ HH: string; mm: string }>,
  companyId: Ref<number>,
  tvStations: Ref<CheckBoxGroup[]>,
  convertStartTimeValue: ComputedRef<string>,
  convertEndTimeValue: ComputedRef<string>
): any => {
  const tvProgramsValue = ref();
  const programsName = computed(() => {
    if (tvProgramsValue.value) {
      return tvProgramsValue.value.map(val => val.name);
    } else {
      return undefined;
    }
  });
  const programsIds = computed(() => {
    if (tvProgramsValue.value) {
      return tvProgramsValue.value.map(val => val.programSeriesId);
    } else {
      return undefined;
    }
  });

  // 番組名取得
  const fetchTvPrograms = async (search?: string) => {
    if (
      selectStationsCodes.value.length &&
      duration &&
      startTimeValue.value &&
      endTimeValue.value &&
      companyId.value
    ) {
      const getTvPrograms = await TvDataSearchApi.getTvdataSearchProgramsSearch(
        selectStationsCodes.value,
        format(duration?.value?.start ?? new Date(), DATE_FORMAT),
        format(duration?.value?.end ?? new Date(), DATE_FORMAT),
        convertStartTimeValue.value,
        convertEndTimeValue.value,
        companyId.value,
        undefined,
        undefined,
        undefined,
        undefined,
        [],
        search
      );
      console.info(getTvPrograms.data);
      return getTvPrograms.data;
    }
  };

  return {
    tvProgramsValue,
    programsName,
    programsIds,
    fetchTvPrograms
  };
};

// 放送
export const useTvStations = (): any => {
  const tvStations = ref<Array<CheckBoxGroup>>([]);
  const stationsValue = ref<Array<string>>([]); // model
  const tvStationsRawData = ref<Array<KeyStation>>([]);
  const broadcastersErrorMessage = computed(() => {
    if (stationsValue.value?.length === 0) {
      return 'いずれか一つは選択してください。';
    } else {
      return undefined;
    }
  });
  const selectStationsCodes = computed(() => {
    return tvStationsRawData.value
      .filter(v =>
        stationsValue.value.find(station => station == v.stationCodes[0])
      )
      .flatMap(v => v.stationCodes);
  });
  return {
    tvStations,
    stationsValue,
    tvStationsRawData,
    selectStationsCodes,
    broadcastersErrorMessage
  };
};

// ターゲット
export const useTargets = (): any => {
  // カスタムターゲットの有無
  const targetName = ref('個人全体・世帯');
  const customTargets = ref<CustomTarget[]>([]);
  const isCustomTarget = ref(false);
  const basicTargetIds = ref<number[]>([]);
  const customTargetId = ref<number[]>([]);
  const initialTargetId = ref<number[]>([]);
  // 性年齢12区分/男女10区分/カスタムターゲットの時に選択されたものが0
  const err = ref<string | undefined>();
  const customTargetList = computed(() => {
    return customTargets.value.map(({ id, name, isEnabled }) => ({
      id,
      label: name,
      isEnabled
    }));
  });
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeTarget = (target: { name: string; value: any }) => {
    targetName.value = target.name;
    // 個人全体・世帯の場合
    if (targetName.value === TARGET_NAME.individualAndHousehold) {
      isCustomTarget.value = false;
      basicTargetIds.value = [];
      customTargetId.value = [];
      err.value = undefined;
      return;
    }
    // カスタムターゲットの場合
    if (targetName.value === TARGET_NAME.customTarget) {
      isCustomTarget.value = true;
      if (Array.isArray(target.value)) {
        if (target.value.length > 0) {
          customTargetId.value = target.value.map(v =>
            v instanceof Object ? v.id : v
          );
        } else {
          customTargetId.value = [];
        }
      } else if (target.value) customTargetId.value = target.value.id;
      err.value =
        customTargetId.value.length === 0
          ? 'いずれか一つは選択してください。'
          : undefined;
      return;
    }
    // 基本属性ターゲットの場合
    isCustomTarget.value = false;
    if (target.value) basicTargetIds.value = target.value;
    err.value =
      basicTargetIds.value.length === 0
        ? 'いずれか一つは選択してください。'
        : undefined;
  };

  return {
    targetName,
    customTargets,
    isCustomTarget,
    basicTargetIds,
    customTargetIds: customTargetId,
    initialTargetId,
    customTargetList,
    err,
    onChangeTarget
  };
};

// 検索履歴
export const useHistory = (
  areaOptions: ReturnType<typeof useAreaOptions>,
  companyId: Ref<number>,
  duration: Ref<DateRange | undefined>,
  areaValue: Ref<AreaInfoIdEnum | undefined>,
  onChangeArea: () => Promise<void>,
  areaSelectOptions: Ref<SelectableAreasList[]>,
  stationsValue: Ref<(string | undefined)[]>,
  startTimeValue: Ref<{ HH: string; mm: string }>,
  endTimeValue: Ref<{ HH: string; mm: string }>,
  cmSponsorsValue: Ref<{ id: number; name: string }[]>,
  cmProductsValue: Ref<{ id: number; name: string }[]>,
  isCmCreativeOptionsOpen: Ref<boolean>,
  cmCreativeDataListItems: Ref<cmCreativeDataListType[]>,
  selectedCmCreativeIds: Ref<number[]>,
  targetName: Ref<string>,
  basicTargetIds: Ref<number[]>,
  customTargetId: Ref<number[]>,
  isCustomTarget: Ref<boolean>,
  initialTargetId: Ref<number[]>,
  targetErrorMessage: Ref<string | undefined>,
  cmTypeValue: Ref<'TIME' | 'SPOT' | undefined>,
  cmTimeValue: Ref<number | undefined>,
  tvProgramsValue: Ref<any>,
  isSubMenuOpen: Ref<boolean>,
  viewingTypeValue: Ref<TvDataSearchCmAggregateHistoryViewingTypeEnum>,
  isConversion15secValue: Ref<boolean>,
  isTotalOptions: Ref<boolean>,
  companySetting: Ref<CompanySettings>
): any => {
  const isOpenModal = ref(false);
  const baseHistoryData: Ref<TvDataSearchCmAggregateHistory[]> = ref([]);
  const historyData = ref();
  const historyKey = ref(0);
  const {
    convertToCmType,
    convertToCmDuration,
    convertToStationName,
    convertToTargetName,
    convertToAreaName,
    convertToIsConversion15sec,
    convertToViewingType
  } = useHistoryModal(companyId.value);

  const _fetchHistory = async () => {
    try {
      if (!companyId.value) {
        toast({ title: 'カンパニーが選択されていません', variant: 'error' });
        return;
      }
      const res = await TvDataSearchApi.getTvdataSearchCmAggregateCompaniesCompanyIdHistories(
        companyId.value
      );
      if (200 <= res.status && res.status < 300) {
        baseHistoryData.value = res.data.list;
        historyData.value = convertToHistoryTableData(res.data.list);
      } else {
        toast({
          title: '失敗',
          message: '検索履歴の取得に失敗しました',
          variant: 'error'
        });
      }
    } catch (e) {
      toast({
        title: '失敗',
        message: '検索履歴の取得に失敗しました',
        variant: 'error'
      });
    }
  };
  const [isFetchingHistory, fetchHistory] = useLoading(_fetchHistory);

  const _deleteHistory = async (args: number): Promise<boolean> => {
    try {
      if (!companyId.value) {
        toast({ title: 'カンパニーが選択されていません', variant: 'error' });
        return false;
      }
      const res = await TvDataSearchApi.deleteTvdataSearchCmAggregateCmAggregateSearchHistoryId(
        args
      );
      if (200 <= res.status && res.status < 300) {
        return true;
      } else {
        toast({
          title: '失敗',
          message: '検索履歴の削除に失敗しました',
          variant: 'error'
        });
        return false;
      }
    } catch (e) {
      toast({
        title: '失敗',
        message: '検索履歴の削除に失敗しました',
        variant: 'error'
      });
      return false;
    }
  };
  const [isDeletingHistory, deleteHistory] = useLoading(_deleteHistory);

  const convertToHistoryTableData = (
    dataList: TvDataSearchCmAggregateHistory[]
  ): DataTabelType[] => {
    const notSet = '指定なし';
    const deleteTarget = '削除されています';
    return dataList.map(data => {
      const today = new Date();
      const cmProductName =
        data.cmProducts?.map(v => v.cmProductName).join(',') || notSet;
      const cmSponsorName =
        data.cmSponsors?.map(v => v.cmSponsorName).join(',') || notSet;
      const targets =
        data.targets
          ?.map(v =>
            v.targetName
              ? v.targetName
              : !data.isCustomTargetNotDeleted
              ? deleteTarget
              : ''
          )
          .join(',') || TARGET_NAME.individualAndHousehold;
      TARGET_NAME.individualAndHousehold;
      const startDate = new Date(data.startDate.replace(/-/g, '/')) ?? today;
      const endDate = new Date(data.endDate.replace(/-/g, '/')) ?? today;
      const area = convertToAreaName(data.areaCode);
      const slash = data.searchedAt.replace(/-/g, '/');
      const dotIndex = slash.indexOf('.');
      const searchedAt = new Date(slash.slice(0, dotIndex)) ?? today;

      const baseConditions = {
        cmProductName: cmProductName,
        cmSponsorName: cmSponsorName,
        targets: targets,
        startDate: startDate,
        endDate: endDate,
        area: area,
        searchedAt: searchedAt,
        id: data.id,
        isCustomTargetNotDeleted: data.isCustomTargetNotDeleted,
        isProductUnchanged: data.isProductUnchanged
      };
      const cmCreatives =
        data.cmCreatives && data.cmCreatives.length > 0
          ? data.cmCreatives
              ?.map(v => v.cmSituation + '(' + v.cmCreativeId + ')')
              .join(',')
          : notSet;
      const allConditions = {
        ...baseConditions,
        searchedAt: format(searchedAt, DATE_FORMAT_SLASH_AND_TIME),
        cmCreatives: cmCreatives,
        buyingKind: convertToCmType(data.buyingKind?.toString()),
        cmDuration: convertToCmDuration(data.cmDuration),
        viewingType: convertToViewingType(data.viewingType),
        isConversion15sec: convertToIsConversion15sec(data.isConversion15sec),
        stations:
          convertToStationName(data.areaCode, data.stations).join(',') ||
          notSet,
        period:
          format(startDate, DATE_FORMAT_SLASH) +
          '〜' +
          format(endDate, DATE_FORMAT_SLASH),
        time: data.startTime + '〜' + data.endTime,
        programSeries:
          data.programSeries?.map(v => v.programName).join(',') || notSet
      };

      return { ...baseConditions, allConditions: allConditions };
    });
  };

  const openHistoryModal = () => {
    isOpenModal.value = true;
    fetchHistory('');
  };

  const setHistory = async (num: number) => {
    const history = baseHistoryData.value.find(v => v.id === num);
    if (!history) return;
    // 期間
    duration.value = {
      start: new Date(history.startDate.replace(/-/g, '/')),
      end: new Date(history.endDate.replace(/-/g, '/'))
    };
    // エリア
    areaValue.value = history.areaCode as AreaInfoIdEnum;
    await onChangeArea();
    // 編集データのエリアが契約エリアに含まれているか判定
    const selectableAreaList = areaSelectOptions.value.flatMap(v =>
      v.areas.map(area => area.id)
    );
    if (!selectableAreaList.includes(areaValue.value ?? '')) {
      areaValue.value = undefined;
    }
    // 企業名
    cmSponsorsValue.value = [];
    if (history.cmSponsors) {
      const tmpCmSponsorsValue: {
        id: number;
        name: string;
      }[] = [];
      history.cmSponsors.map(v => {
        tmpCmSponsorsValue.push({
          name: v.cmSponsorName ?? '',
          id: v.cmSponsorId ?? 0
        });
      });
      cmSponsorsValue.value = tmpCmSponsorsValue;
    }
    // 商品／ブランド
    cmProductsValue.value = [];
    if (history.cmProducts) {
      const tmpCmProductsValue: {
        id: number;
        name: string;
      }[] = [];
      history.cmProducts.map(v => {
        tmpCmProductsValue.push({
          id: v.cmProductId ?? 0,
          name: v.cmProductName ?? ''
        });
      });
      cmProductsValue.value = tmpCmProductsValue;
    }
    // CM素材
    selectedCmCreativeIds.value = [];
    if (history.cmCreatives && history.cmCreatives.length > 0) {
      isCmCreativeOptionsOpen.value = true;
      cmCreativeDataListItems.value = history.cmCreatives.map(v => ({
        id: v.cmCreativeId ?? 0,
        cmDuration: v.cmDuration ?? 0,
        cmSituation: v.cmSituation ?? '',
        cmTalent: v.cmTalent ?? '',
        cmBgm: v.cmBgm ?? '',
        cmRemarks: v.cmRemarks ?? '',
        select: v.cmCreativeId ?? 0
      }));
      selectedCmCreativeIds.value = history.cmCreatives?.map(
        v => v.cmCreativeId ?? 0
      );
    } else {
      isCmCreativeOptionsOpen.value = false;
    }
    // ターゲット
    basicTargetIds.value = [];
    customTargetId.value = [];
    targetErrorMessage.value = undefined;
    isCustomTarget.value = false;
    targetName.value = convertToTargetName(history.targetType);
    if (history.targets) {
      if (targetName.value === TARGET_NAME.customTarget) {
        customTargetId.value = history.targets?.map(v => {
          return v.targetId ?? 0;
        });
        initialTargetId.value = customTargetId.value;
        isCustomTarget.value = true;
      } else if (targetName.value === TARGET_NAME.individualAndHousehold) {
        isCustomTarget.value = false;
      } else {
        basicTargetIds.value = history.targets?.map(v => {
          return v.targetId ?? 0;
        });
        initialTargetId.value = basicTargetIds.value;
        isCustomTarget.value = false;
      }
    }
    // 視聴種別
    viewingTypeValue.value = isTotalOptions.value
      ? history.viewingType ?? undefined
      : TvDataSearchCmAggregateHistoryViewingTypeEnum.Realtime;
    // 15秒換算
    if (companySetting.value === undefined) {
      isConversion15secValue.value = history.isConversion15sec ?? false;
    } else {
      isConversion15secValue.value =
        history.isConversion15sec ?? companySetting.value.isConversion15sec;
    }
    // CM種別
    cmTypeValue.value = history.buyingKind ?? undefined;
    // CM秒数
    cmTimeValue.value = history.cmDuration ?? undefined;
    // 放送局
    stationsValue.value = history.stations;
    // 番組名
    tvProgramsValue.value = undefined;
    if (history.programSeries) {
      const tmpTvProgramsValue: {
        programSeriesId: number;
        name: string;
      }[] = [];
      history.programSeries.map(v => {
        tmpTvProgramsValue.push({
          programSeriesId: v.programSeriesId ?? 0,
          name: v.programName ?? ''
        });
      });
      tvProgramsValue.value = tmpTvProgramsValue;
    }
    // 時間帯
    startTimeValue.value.HH = history.startTime.split(':')[0];
    startTimeValue.value.mm = history.startTime.split(':')[1];
    endTimeValue.value.HH = history.endTime.split(':')[0];
    endTimeValue.value.mm = history.endTime.split(':')[1];

    isSubMenuOpen.value = true; // 検索オプションを開く
    historyKey.value += 1; // 画面表示更新
  };

  return {
    isOpenModal,
    historyData,
    isSubMenuOpen,
    historyKey,
    isFetchingHistory,
    isDeletingHistory,
    fetchHistory,
    deleteHistory,
    openHistoryModal,
    setHistory
  };
};

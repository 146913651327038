import { TvDataSearchApi, CompanyApi } from '@/api';
import { useRoute } from 'vue-router';
import {
  TvDataSearchCmGrpAllList,
  CompanySettingsViewingTypeEnum,
  CompanySettings
} from '@/api/openapi';
import { httpCode } from '@/common/constant';
import { handleError } from '@/common/handleError';
import { toast } from '@/components/ui/Toast';
import { COMPANY_ROUTES } from '@/router';
import { Ref, ref } from 'vue';
import { dsTargetType } from '../targets';

import useLoading from '@/composables/loading';

export interface ICmAggregateSearchCondition {
  companyId: number;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  areaCode: string;
  useCustomTarget: boolean;
  targetType: dsTargetType;
  stations: Array<string>;
  isConversion15sec: boolean;
  viewingType: CompanySettingsViewingTypeEnum;
  cmSponsorIds?: Array<number>;
  cmProductIds?: Array<number>;
  targetIds?: Array<number>;
  buyingKind?: string;
  cmDuration?: number;
  cmCreativeIds?: Array<number>;
  programSeriesIds?: Array<number>;
}

type UseCmAggregateSearchReturnType = {
  isCompanySettingLoading: Ref<boolean>;
  companySettings: Ref<CompanySettings>;
  onSearch: (condition: ICmAggregateSearchCondition) => Promise<void>;
  data: Ref<TvDataSearchCmGrpAllList | undefined>;
  isInitialDisplay: Ref<boolean>;
  isDataLoading: Ref<boolean>;
  isShowTable: Ref<boolean>;
  breadcrumbs: Ref<{
    parents: { name: string; label: string }[];
    current: { label: string };
  }>;
};

export const useCmAggregateSearch = (): UseCmAggregateSearchReturnType => {
  const route = useRoute();
  const { params } = route;
  const companyId = ref(Number(params.companyId));
  const conditionRef = ref<ICmAggregateSearchCondition>();
  const companySettings = ref({} as CompanySettings);

  const data = ref<TvDataSearchCmGrpAllList>();
  const isInitialDisplay = ref(true);
  const isDataLoading = ref(false);
  const isShowTable = ref(false);

  const [isCompanySettingLoading, fetchCompanySettings] = useLoading(
    _fetchCompanySettings
  );

  const onSearch = async (condition: ICmAggregateSearchCondition) => {
    conditionRef.value = condition;
    try {
      isInitialDisplay.value = false;
      isDataLoading.value = true;
      isShowTable.value = false;

      const response = await TvDataSearchApi.getTvdataSearchCmAggregateSearch(
        condition.companyId,
        condition.startDate,
        condition.endDate,
        condition.startTime,
        condition.endTime,
        condition.areaCode,
        condition.targetType,
        condition.stations,
        condition.isConversion15sec,
        condition.viewingType,
        condition.cmSponsorIds,
        condition.cmProductIds,
        condition.targetIds,
        condition.buyingKind,
        condition.cmDuration,
        condition.cmCreativeIds,
        condition.programSeriesIds
      );
      // day が最小単位なはずなので、それの件数が > 0 なら問題ないと言う考え方
      data.value = response.data;
      isShowTable.value = true;
    } catch (e) {
      handleError(e);
      isInitialDisplay.value = true;
      if (e.status === httpCode.forbidden) {
        toast({ title: '失敗', message: e.message, variant: 'error' });
      }
    } finally {
      isDataLoading.value = false;
    }
  };

  const breadcrumbs = ref({
    parents: [
      { name: COMPANY_ROUTES.top, label: 'ホーム' },
      { name: '', label: 'TVデータサーチ' }
    ],
    current: { label: 'CM期間集計' }
  });

  (async () => {
    companySettings.value = await fetchCompanySettings(companyId.value);
  })();

  return {
    isCompanySettingLoading,
    companySettings,
    onSearch,
    data,
    isInitialDisplay,
    isDataLoading,
    isShowTable,
    breadcrumbs
  };
};
const _fetchCompanySettings = async (companyId): Promise<CompanySettings> => {
  try {
    const { data } = await CompanyApi.getCompaniesCompanyIdSettings(companyId);
    return data;
  } catch (e) {
    console.error(e);
    return {} as CompanySettings;
  }
};

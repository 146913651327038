import {
  TvDataSearchCmGrpAllListConditions,
  TvDataSearchCmGRPDataList
} from '@/api/openapi';
import { FlatDataType, TableDataType } from '@/composables/download';
import { format, parse } from 'date-fns';
import { computed, Ref, ref } from 'vue';
import { RouteParams } from 'vue-router';

const dateByPeriodKey = 'dataByPeriod';
const periodKey = 'period';
const allPeriodKey = 'allPeriod';

export const HEADER_TITLE = 'TVAL - CM期間集計';

export const HEADER_LABELS = {
  period: '期間',
  areaName: 'エリア',
  sampleSize: '期間内最新サンプルサイズ',
  cmSponsorNames: '企業名',
  cmProductNames: '商品／ブランド名',
  cmCreativeNames: 'CM素材別',
  cmType: 'CM種別',
  cmDuration: 'CM秒数',
  stations: '放送局',
  programs: '番組名',
  periodType: '期間区切り',
  viewingType: '視聴種別',
  reportType: '利用データ',
  convertIn15Seconds: '15秒換算',
  unit: '単位',
  createdAt: '作成日時',
  timeZone: '時間帯',
  from: 'データ提供元'
};

export const FIXED_CONDITIONS = {
  unit: '%',
  from: 'Switch Media, Inc.'
};

type sortType = {
  label: string;
  sort: number;
};

const CMTYPE_SORT_VALUE: Array<sortType> = [
  { label: 'すべて', sort: 1 },
  { label: 'TIME', sort: 2 },
  { label: 'PT', sort: 3 },
  { label: 'SB', sort: 4 }
];

const dateRegex = new RegExp(
  `(?<year>\\d{4})年(?<month>\\d{2})月(?<day>\\d{2})日.+`
);
const durationRegex = new RegExp(`(?<start>.+)[~〜](?<end>.+)`);

export const getFilename = (
  _condition: TvDataSearchCmGrpAllListConditions,
  _params: RouteParams
): string => {
  const _duration = _condition.period;
  const dateStrings = durationRegex.exec(_duration)?.groups;
  if (dateStrings === undefined) return 'invalid';

  const parseDate = (dateString: string | undefined) => {
    if (dateString === undefined) return undefined;
    const groups = dateRegex.exec(dateString)?.groups;
    if (groups === undefined) return undefined;
    return groups['year'] + groups['month'] + groups['day'];
  };

  const start = parseDate(dateStrings['start']);
  const end = parseDate(dateStrings['end']);

  return `TVAL_CMSUM_c${_params.companyId}_${start}-${end}`;
};

const preprocess = (
  _data: TvDataSearchCmGRPDataList
): {
  labels: Array<string>;
  keys: Array<string>;
  variableLabels: Array<string>;
  data: Array<FlatDataType>;
} => {
  const header = Object.entries(_data.header).filter(
    value => value[0] !== dateByPeriodKey
  );
  const keys = header.map(h => h[0]);
  const labels = header.map(h => h[1]);
  const variableLabels = _data.header.dataByPeriod;
  const data = _data.data.map(value => {
    const result = {};
    keys.forEach(key => (result[key] = value[key]));
    result[dateByPeriodKey] = value.dataByPeriod;
    return result;
  });

  return {
    labels,
    keys,
    variableLabels,
    data
  };
};

export const toVertically = (
  _data: TvDataSearchCmGRPDataList
): TableDataType => {
  const { data, variableLabels, labels, keys } = preprocess(_data);
  const result: Array<FlatDataType> = [];
  keys.push(periodKey, dateByPeriodKey);
  keys.splice(keys.indexOf(allPeriodKey), 1);
  labels.push('期間', 'GRP');
  labels.splice(labels.indexOf('期間計'), 1);
  data.forEach(value => {
    const grp = value[allPeriodKey];
    delete value[allPeriodKey];
    const dp = value[dateByPeriodKey] as Array<number> | null;
    if (dp !== null) {
      dp.forEach((d, idx) => {
        const v = { ...value };
        v[dateByPeriodKey] = d;
        v[periodKey] = variableLabels[idx];
        result.push(v);
      });
    }
    value[periodKey] = '期間計';
    value[dateByPeriodKey] = Number(grp);
    result.push(value);
  });
  return { data: result, labels, keys };
};

export const toHorizontally = (
  _data: TvDataSearchCmGRPDataList
): TableDataType => {
  const { data, variableLabels, labels, keys } = preprocess(_data);
  labels.push(...variableLabels);
  keys.push(...variableLabels);
  const result = data.map(value => {
    const dp = value[dateByPeriodKey] as Array<number> | null;
    if (dp !== null) {
      dp.forEach((d, idx) => {
        const label = variableLabels[idx];
        value[label] = d;
      });
    }
    delete value[dateByPeriodKey];
    return value;
  });
  return {
    data: result,
    labels,
    keys
  };
};

type ButtonValueType = string;

type ButtonType = {
  label: string;
  value: ButtonValueType;
};

interface UsePeriodUnitReturnType {
  buttons: Array<ButtonType>;
  current: Ref<ButtonValueType>;
  formatColumnLabel: (label: string) => string;
}

export const usePeriodUnit = (): UsePeriodUnitReturnType => {
  const units: Array<ButtonType> = [
    {
      label: '月別',
      value: 'month'
    },
    {
      label: '週別',
      value: 'week'
    },
    {
      label: '日別',
      value: 'day'
    }
  ];

  const current = ref(units[0].value);

  const isMonthly = computed(() => current.value === units[0].value);

  const formatColumnLabel = (label: string): string => {
    if (isMonthly.value) {
      return label;
    } else {
      try {
        const dt = parse(label, 'yyyy/MM/dd', new Date());
        return format(dt, 'MM/dd');
      } catch (e) {
        return label;
      }
    }
  };

  return {
    buttons: units,
    current,
    formatColumnLabel
  };
};

type checkBoxType = {
  label: string;
  isCheck: Ref<boolean>;
};

export const opt: { [key: string]: checkBoxType } = {
  station: { label: '放送局', isCheck: ref(false) },
  cmCreative: { label: 'CM素材', isCheck: ref(false) },
  buyingKind: { label: 'CM種別', isCheck: ref(false) }
};

export const getCmTypeSortValue = (_cmType: string): number => {
  return CMTYPE_SORT_VALUE.find(v => v.label === _cmType)?.sort ?? 99;
};

export const sleep = (second: number): Promise<void> => {
  return new Promise(resolve => setTimeout(resolve, second));
};

import { Ref, ref } from 'vue';

export interface IPage {
  index: number;
  limit: number;
}

const _pagingDefault = {
  index: 0,
  indexToken: '',
  limit: 20
};

export type UsePagingReturnType = {
  index: Ref<number>;
  limit: Ref<number>;
  setDefault: () => void;
  onPaging: (index?: number, limit?: number) => void;
};

const usePaging = (
  pagingDefault: IPage = _pagingDefault
): UsePagingReturnType => {
  const index = ref<number>(pagingDefault.index);
  const limit = ref<number>(pagingDefault.limit);

  const onPaging = (_index?: number, _limit?: number) => {
    if (_index !== undefined) {
      index.value = _index;
    }

    if (_limit !== undefined) {
      limit.value = _limit;
    }
  };

  const setDefault = () => {
    index.value = pagingDefault.index;
    limit.value = pagingDefault.limit;
  };

  return {
    index,
    limit,
    setDefault,
    onPaging
  };
};

/**
 * Array.mapに入れて使う想定
 * @param arr
 * @param key
 */
export const groupBy = <T, K extends string | symbol | number>(
  arr: Array<T>,
  key: (d: T) => K
): Array<Array<T>> => {
  const map = new Map();
  arr.forEach(val => {
    const k = key(val);
    if (!map.has(k)) map.set(k, []);
    map.get(k)?.push(val);
  });
  return Array.from(map.values());
};

export default usePaging;
